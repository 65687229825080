/*
 * Módulo para carregamento automatico dos DependentSelect.
 *
 * Utiliza os seguintes parâmetros:
 *
 *   <... data-toggle='dependent-select'>
 */

import { DependentSelect } from '../components/dependent-select/dependent-select'

document.addEventListener('turbo:load', () => {

  $('[data-toggle=dependent-select]:not(.ignore-select2)').each(function() {
    const mainContainer = $(this),
          dependentSelect = new DependentSelect(mainContainer),
          childContainer = $(mainContainer.data('child-container'))

    mainContainer.select2()
    childContainer.select2()
  })

})
